import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Here we reward the most active users and introduce the three winners of
the 2017 User Awards. Over 1.000 trails were added by the Trailguide
community last year, pictures were uploaded and trails reviewed. We want
to thank you all for contributing and making Trailguide a great and very
useful guide for everyone. That isn't possible without a strong community
like we are and we look forward for a great season with you in 2018.`}</p>
    <br />
    <h2>{`User of the year`}</h2>
    <h4>{`Øyvind Svare Odde - 33 trails uploaded`}</h4>
    <Image src="news/oyvindsvareodde.jpg" mdxType="Image" />
    <p><strong parentName="p">{`What is your local riding location?`}</strong></p>
    <p>{`I live in Vågå, Norway. I usually ride trails in the mountains around here.`}</p>
    <br />
    <p><strong parentName="p">{`How many days did you spent on your bike last year?`}</strong></p>
    <p>{`From what I can see on Strava I rode 179 days in 2017, and I think about
100 of them were on trails.`}</p>
    <br />
    <p><strong parentName="p">{`What do you use to track your rides (gps device, software, ...)?`}</strong></p>
    <p>{`I use a Garmin Edge 520 and I upload it to Strava.`}</p>
    <br />
    <p><strong parentName="p">{`What are your favourite bike destinations you traveled to?`}</strong></p>
    <p>{`I haven't been mountain biking outside Norway yet, and not much outside Vågå.
I have enjoyed Øyerfjellet and Nordmarka.`}</p>
    <br />
    <p><strong parentName="p">{`Where do you want to ride in the future?`}</strong></p>
    <p>{`First and foremost here in Vågå! The Alps maybe? Transalps looks «fun».`}</p>
    <br />
    <p><strong parentName="p">{`What makes a perfect day out biking for you?`}</strong></p>
    <p>{`A day with warm summer weather, good company and trails in the mountains in Vågå.`}</p>
    <br />
    <p><strong parentName="p">{`How do you use Trailguide?`}</strong></p>
    <p>{`I put local trails on the map, in hope more people to use them.`}</p>
    <br />
    <p><strong parentName="p">{`What functions would you like to have in Trailguide?`}</strong></p>
    <p>{`A winter map for fatbiking maybe?`}</p>
    <br />
    <p>{`Prizes: Evoc backpack, Trailguide jersey, Trailguide calendar 2018, sticker set.`}</p>
    <br />
    <h2>{`Second place`}</h2>
    <h4>{`Birger Patrick - 22 trails uploaded`}</h4>
    <Image src="news/birgerpatrick.jpg" mdxType="Image" />
    <p><strong parentName="p">{`What is your local riding location?`}</strong></p>
    <p>{`I ride primarily in my backyard woods, which is in the south east region of
Norway - Sarpsborg and Fredrikstad, but I also have a cabin in Kongsberg and
I ride there every time we go there.`}</p>
    <br />
    <p><strong parentName="p">{`How many days did you spent on your bike last year?`}</strong></p>
    <p>{`Um, Strava gives me 494 hours of moving time :) I ride all year, at least 3
trail rides per week, and I commute to work on a CX.`}</p>
    <br />
    <p><strong parentName="p">{`What do you use to track your rides (gps device, software, ...)?`}</strong></p>
    <p>{`I use a Garmin 520, which uploads to Garmin Connect, Strava, Trainingpeaks
and a few other sites I've forgotten...`}</p>
    <br />
    <p><strong parentName="p">{`What are your favourite bike destinations you traveled to?`}</strong></p>
    <p>{`So far I haven't done much outside of Norway. I keep going back to Gothenburg,
Sweden, since it's quite close, and has excellent trails of all types.
I've also enjoyed some road cycling on Mallorca which is a very different
experience compared to what I'm used to.`}</p>
    <br />
    <p><strong parentName="p">{`Where do you want to ride in the future?`}</strong></p>
    <p>{`I have a too long bucket list! All over the Alps, B.C in Canada, Madeira,
Scotland...just to mention a few. I'd like to explore more destinations
in Norway too. Lots of races would give me a kick - like TransAlp,
B.C Bike Race, Swiss Epic - maybe Megavalanche!`}</p>
    <br />
    <p><strong parentName="p">{`What makes a perfect day out biking for you?`}</strong></p>
    <p>{`Riding bikes, especially on trails, is my happy place - always. Sometimes
on my own, but mostly with good friends, the Mrs or the entire family.
Sometimes to become faster, other times just to enjoy the scenery, talk,
ride something scary - and have a great time.`}</p>
    <br />
    <p><strong parentName="p">{`How do you use Trailguide?`}</strong></p>
    <p>{`I use it to recon new riding areas - before going, or whenever I visit a
location. It's a great help when planning rides, and also to get my
bearings when I'm out on the trails. I also use it to promote the areas I've
enjoyed, to share the trails and hope someone else can enjoy riding there
as much as I do.`}</p>
    <br />
    <p><strong parentName="p">{`What functions would you like to have in Trailguide?`}</strong></p>
    <p>{`I think it is great as is - if anything, maybe the ability to download
selected areas and use them with GPS, but without good cell reception.`}</p>
    <br />
    <p>{`Prizes: Mudhugger, Trailguide jersey, Trailguide calendar 2018, sticker set.`}</p>
    <br />
    <h2>{`Third place`}</h2>
    <h4>{`Daniel Jarr - 17 trails uploaded`}</h4>
    <Image src="news/danieljarr.jpg" mdxType="Image" />
    <p><strong parentName="p">{`What is your local riding location?`}</strong></p>
    <p>{`At the moment I ride mainly in the Taunus, close to Frankfurt am Main.
But I travel at least once a year somewhere in Europe. Last year I was in
Norway with two good friends.`}</p>
    <br />
    <p><strong parentName="p">{`How many days did you spent on your bike last year?`}</strong></p>
    <p>{`I ride to work everyday 30km through the forest. Also in really bad weather.`}</p>
    <br />
    <p><strong parentName="p">{`What do you use to track your rides (gps device, software, ...)?`}</strong></p>
    <p>{`The phone with the app Locus Pro.`}</p>
    <br />
    <p><strong parentName="p">{`What are your favourite bike destinations you traveled to?`}</strong></p>
    <p>{`That’s really a difficult question. The trails in Norway and on the Azores
are very challenging and really interesting. Even in my hometown Hamburg
there are very cool trails. My favourite trail is in Gran Canaria: From the
highest mountain, the Pico, down to the Sea to Agaete.`}</p>
    <br />
    <p><strong parentName="p">{`Where do you want to ride in the future?`}</strong></p>
    <p>{`I would like to go to the Andes, but also in Europe there are tons of dream
destinations.`}</p>
    <br />
    <p><strong parentName="p">{`What makes a perfect day out biking for you?`}</strong></p>
    <p>{`Sun, nature, technical trails and my camera in the backpack.`}</p>
    <br />
    <p><strong parentName="p">{`How do you use Trailguide?`}</strong></p>
    <p>{`Together with a friend I run the bike blog trail-business.com. We use
Trailguide to upload all our tours. So we have a host for the tours and the
community benefits from and keeps the tracks up to date.`}</p>
    <br />
    <p><strong parentName="p">{`What functions would you like to have in Trailguide?`}</strong></p>
    <p>{`I often plan our tours with google earth to get a good impression of the
landscape. For this, the pictures other users uploaded are really helpful.
It would be cool when Trailguide have this function to get a better
live-impression.`}</p>
    <br />
    <p>{`Prizes: Fox gloves, Trailguide jersey, Trailguide calendar 2018, sticker set.`}</p>
    <Image src="news/userawards1.jpg" mdxType="Image" />
    <p>{`Thanks a lot to everyone who contributed. We hope you enjoy Trailguide and
we are looking forward to make it even better together with all of you in 2018.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      